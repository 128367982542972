import { createApp, h } from 'vue'
import { createInertiaApp, usePage } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { vfmPlugin } from 'vue-final-modal'
import moment from 'moment'
import { route } from 'ziggy-js'
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import * as Sentry from '@sentry/vue'
import sentryConfig from './Utils/sentry.js'

import { asset } from '@/Utils/assetHelpers.js'

// packages with styles
import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'

import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

import '@algolia/autocomplete-theme-classic/dist/theme.css'

import '../css/app.css' // local components
import UseModal from './Mixins/UseModal'

window.moment = moment

window.Pusher = Pusher

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  forceTLS: true,
})

window.__ = (message, replace) => {
  let translation = message

  if (replace) {
    Object.entries(replace).forEach(([key, value]) => {
      translation = translation.replace(':' + key, value)
    })
  }

  return translation
}

window.route = (routeName, params) => {
  if (routeName === undefined) {
    // forward calls to route() through to ziggy
    return route(routeName, params)
  }

  let localisedRoute = `${usePage().props.session?.language ?? 'en'}.${routeName}`
  if (route().has(localisedRoute)) {
    return route(localisedRoute, params)
  }

  return route(routeName, params)
}

createInertiaApp({
  resolve: (name) =>
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  progress: {
    color: '#ea580c',
    delay: 0,
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({
      render: () => h(App, props),
    })
      .use(plugin)
      .use(
        vfmPlugin({
          key: '$vfm',
          componentName: 'VueFinalModal',
          dynamicContainerName: 'ModalsContainer',
        })
      )
      .use(VCalendar)
      .use(FloatingVue, { disposeTimeout: 500 })
      .component(
        'FilePond',
        vueFilePond(FilePondPluginImagePreview, FilePondPluginFileValidateType)
      )
      .mixin(UseModal)
      .mixin({
        methods: {
          moment: window.moment,
          __: window.__,

          route(routeName, params) {
            if (routeName === undefined) {
              // forward calls to route() through to ziggy
              return route(routeName, params)
            }

            let localisedRoute = `${usePage().props.session?.language ?? 'en'}.${routeName}`

            if (route().has(localisedRoute)) {
              return route(localisedRoute, params)
            }

            return route(routeName, params)
          },

          tenantAsset(uri, tenant = this.$page.props.tenant) {
            let prefix = document.head.querySelector('meta[name="tenant-prefix"]').content

            return prefix + `/${tenant.id}/` + uri.replace(/^\/+/, '')
          },

          tenantFile(file) {
            if (file) {
              let uri = file.filepath + file.filename

              return this.tenantAsset(uri.replace(/^\/+/, ''))
            }

            return asset('assets/images/placeholder_5x4.png')
          },

          hasRole(role, user = null) {
            const roles = (user ? user.roles : this.$page.props.auth.roles).map((r) =>
              typeof r === 'string' ? r : r.name
            )
            const requiredRoles = Array.isArray(role) ? role : [role]

            return roles.some((role) => requiredRoles.includes(role))
          },

          siteLocale() {
            switch (usePage().props.tenant.settings.currency_code) {
              case 'AUD':
                return 'en-AU'

              case 'USD':
                return 'en-US'
            }
          },

          number(number) {
            return number.toLocaleString(this.siteLocale)
          },

          price(amount, currencyCode = null, narrowSymbol = false) {
            return new Intl.NumberFormat(this.siteLocale, {
              style: 'currency',
              currencyDisplay: narrowSymbol ? 'narrowSymbol' : 'symbol',
              currency: currencyCode ?? this.$page.props.tenant.settings.currency_code,
            }).format(amount)
          },

          routeToAuction(auction) {
            return `/products/${auction.url_title}-${auction.id}`
          },
        },
      })

    app.config.globalProperties.$moment = moment

    // todo: temporarily disabled due to custom page invoked errors
    // if (import.meta.env.MODE !== 'development') {
    // app.config.errorHandler = () => {
    // router.reload({ data: { error: 500 } })
    // }
    // }

    if (import.meta.env.PROD) {
      Sentry.init({
        app,
        dsn: 'https://1ea0c30271c78155078b9c54c19eb893@o4507704896061440.ingest.us.sentry.io/4507704902483968',
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        ...sentryConfig,
      })
    }

    app.mount(el)
  },
})
